/*--------------------------------------------------------------
22. Post
----------------------------------------------------------------*/
.st-load-more {
  display: flex;
  justify-content: flex-end;
}
.st-post.st-style3 {
  a:hover {
    color: $blue;
  }

  .st-post-thumb {
    display: block;
    margin-bottom: 25px;
    overflow: hidden;
    position: relative;

    &:after {
      content: "";
      position: absolute;
      height: 100%;
      width: 100%;
      background-color: $black1;
      opacity: 0;
      left: 0;
      top: 0;
      transition: all 0.3s ease;
    }

    img {
      width: 100%;
    }

    &:hover {
      &:after {
        opacity: 0.3;
      }
    }
  }

  .st-post-info {
    margin-bottom: 33px;
  }

  .st-post-title {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 9px;
  }

  .st-post-meta {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 16px;

    > span {
      position: relative;
      color: $black3;

      &:not(:last-child) {
        padding-right: 15px;
        margin-right: 15px;
      }

      &:not(:last-child) {
        &:after {
          content: "";
          position: absolute;
          height: 12px;
          width: 1px;
          background-color: $black4;
          top: 6px;
          right: 0;
        }
      }
    }
  }

  &.st-type1 {
    background-color: #f8f8fb;
    padding: 30px;
    border-radius: 8px;
    overflow: hidden;

    .st-post-thumb {
      overflow: hidden;
      margin: -30px;
      margin-bottom: 25px;
    }
  }

  &.st-color1 {
    .st-link-hover-wrap .st-link-hover {
      background-color: $light-blue;
    }

    .st-link-hover-wrap .st-link-hover i,
    a:not(.st-btn):hover {
      color: $light-blue;
    }
  }

  &.st-color2 {
    .st-link-hover-wrap .st-link-hover {
      background-color: $pink;
    }

    .st-link-hover-wrap .st-link-hover i,
    a:not(.st-btn):hover {
      color: $pink;
    }
  }
}
/* Example CSS styles for a more visually appealing design */
.st-post-info {
  max-width: 800px; /* Adjust as needed */
  margin: 0 auto; /* Center the content */
}
.facts-list {
  counter-reset: fact-counter; /* Initialize the counter */
}

.numbered-fact {
  counter-increment: fact-counter; /* Increment the counter for each fact */
}

.numbered-fact::before {
  content: counter(fact-counter) ". "; /* Display the counter value with a period and space */
  font-weight: bold;
  margin-right: 5px;
}
.intro-text {
  font-size: 1.1rem;
  line-height: 1.6;
  margin-bottom: 20px;
}

.section-title {
  color: #333; /* Darker color for titles */
  margin-bottom: 15px;
}

.facts-list {
  list-style-type: none; /* Remove default list styling */
  padding: 0;
}

.fact-title {
  font-weight: bold;
}

.video-container {
  margin-top: 20px;
}
.st-post.st-style2 {
  box-shadow: 0px 5px 10px 0px rgba(106, 106, 106, 0.15);
  text-align: center;
  border-radius: 7px;
  overflow: hidden;

  .st-post-thumb {
    overflow: hidden;
    display: block;

    img {
      width: 100%;
    }
  }

  .st-post-info {
    position: relative;
    padding: 66px 20px 25px 20px;
  }

  .st-post-avatar {
    position: absolute;
    top: -40px;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 50%;
    overflow: hidden;
    display: block;

    .st-post-avatar-text {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      border-radius: 50%;
      background-color: rgba($blue, 0.9);
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 10px;
      color: $white;
      font-weight: 400;
      opacity: 0;
      transition: all 0.4s ease;

      &:hover {
        opacity: 1;
      }
    }
  }

  .st-post-title {
    font-size: 18px;
    margin-bottom: 11px;

    &:hover {
      a {
        color: $blue;
      }
    }
  }

  .st-post-date {
    border-top: 1px solid $black4;
    padding: 12px 20px;
  }
  /* Default styles for the button */
  .st-post-footer .responsive-btn {
    display: block; /* Make button block-level to take full width */
    padding: 10px 20px;
    font-size: 16px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
    box-sizing: border-box;
  }

  /* Styles for larger screens */
  @media (min-width: 768px) {
    .st-post-footer .responsive-btn {
      font-size: 18px;
      padding: 12px 24px;
    }
  }

  /* Styles for smaller screens */
  @media (max-width: 767px) {
    .st-post-footer .responsive-btn {
      font-size: 14px;
      padding: 8px 16px;
      max-width: 100%;
    }
  }

  /* Styles for extra small screens */
  @media (max-width: 480px) {
    .st-post-footer .responsive-btn {
      font-size: 12px;
      padding: 6px 12px;
      max-width: 100%;
    }
  }

  .st-post-footer {
    position: relative;
    height: 49px;

    .st-post-btn {
      position: absolute;
      height: 100%;
      width: 100%;
      top: 55px;
      left: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 500;
      background-color: $blue;
      color: $white;

      &:hover {
        color: rgba(255, 255, 255, 0.8);
      }
    }
  }

  &:hover {
    .st-post-btn {
      top: 0;
    }
  }

  &.st-size-lg {
    .st-post-title {
      font-size: 28px;
    }
  }
}

.st-post-widget-list > li:not(:last-child) {
  margin-bottom: 15px;
}

.st-post.st-style1 {
  display: flex;

  .st-post-thumb {
    flex: none;
    margin-right: 10px;
    width: 70px;
  }

  .st-post-title {
    font-size: 15px;
    font-weight: 500;
    margin-bottom: 4px;

    a {
      &:hover {
        color: $blue;
      }
    }
  }

  .st-post-date {
    font-size: 13px;
    line-height: 1.6em;
  }

  &.st-style1 {
    .st-post-title {
      a {
        &:hover {
          color: $light-blue;
        }
      }
    }
  }
  &.st-style1 {
    .st-post-title {
      a {
        &:hover {
          color: $pink;
        }
      }
    }
  }
}

.st-categories.st-style1 {
  margin-top: -4px;

  li {
    font-size: 14px;
    line-height: 1.6em;
    margin-bottom: -2px;
    color: #b5b5b5;

    a {
      &:hover {
        color: $blue;
      }
    }
  }

  &.st-color1 {
    li {
      a {
        &:hover {
          color: $light-blue;
        }
      }
    }
  }
}

.st-sidebar-widget {
  .st-post-widget-list > li:not(:last-child) {
    border-bottom: 1px solid $black4;
    padding-bottom: 12px;
    margin-bottom: 12px;
  }

  .st-post.st-style1 .st-post-thumb {
    width: 80px;
  }
}

.st-post-pagination {
  margin: 0;
  justify-content: center;

  .page-link {
    padding: 0.73rem 1.15rem;
    color: $black2;
    background-color: $white;
    border-color: $black4;

    &:hover {
      background-color: $black5;
      color: $black1;
    }
  }

  .page-item {
    &.disabled {
      .page-link {
        color: $black3;
      }
    }

    &.active {
      .page-link {
        color: $white;
        background-color: $blue;
        border-color: $blue;
        position: relative;
        z-index: 1;
      }
    }
  }
  &.st-color1 {
    .page-item {
      &.active {
        .page-link {
          background-color: $light-blue;
          border-color: $light-blue;
        }
      }
    }
  }
  &.st-color2 {
    .page-item {
      &.active {
        .page-link {
          background-color: $pink;
          border-color: $pink;
        }
      }
    }
  }
}

/*--------------------------------------------------------------
13. Video Block
----------------------------------------------------------------*/
.st-video-block.st-style1 {
  border-radius: 50% 7px 50% 50%;

  .st-video-block-img {
    height: 475px;
    width: 100%;
    background-size: cover;
    background-position: center;

    &:before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      width: 100%;
      background-color: #000;
      opacity: 0.1;
      transition: all 0.3s ease;
    }
  }

  &:hover {
    .st-video-block-img:before {
      opacity: 0.3;
    }
  }

  &.st-type1 {
    border-radius: 0;
    max-width: 820px;
    margin: auto;
    border: 10px solid #fff;
    box-shadow: 0px 0px 50px 0px rgba(51, 51, 51, 0.2);

    .st-video-block-img {
      height: 500px;

      &:before {
        opacity: 0;
      }
    }

    .st-play-btn.st-style1 svg {
      fill: $light-blue;
    }

    .st-video-animaiton,
    .st-video-animaiton span {
      box-shadow: 0 0 0 5px $light-blue;
    }
  }
}

.st-play-btn.st-style1.st-color1 {
  svg {
    fill: $pink;
  }

  .st-video-animaiton,
  .st-video-animaiton span {
    box-shadow: 0 0 0 5px $pink;
  }
}

.st-play-btn.st-style1 {
  position: absolute;
  z-index: 1;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  color: #fff;
  font-size: 80px;
  cursor: pointer;

  svg {
    width: 80px;
    fill: $white;
  }
}

.st-video-animaiton,
.st-video-animaiton span {
  position: absolute;
  top: 0;
  width: 72px;
  height: 71px;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  display: block;
  border-radius: 50%;
  -webkit-animation: st-video-animaion 2s linear infinite;
  animation: st-video-animaion 2s linear infinite;
  -webkit-box-shadow: 0 0 0 11px #fff;
  box-shadow: 0 0 0 5px #fff;
}

@keyframes st-video-animaion {
  0% {
    transform: scale(1);
    opacity: 0;
  }

  25% {
    transform: scale(1);
    opacity: 0.75;
  }

  100% {
    transform: scale(1.5);
    opacity: 0;
  }
}

/*===Video Popup===*/
.embed-responsive-16by9::before {
  padding-top: 56.25%;
}
.embed-responsive::before {
  display: block;
  content: '';
}
.embed-responsive .embed-responsive-item,
.embed-responsive embed,
.embed-responsive iframe,
.embed-responsive object,
.embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}
.st-pd-video .st-video-open,
.st-sample-img .st-video-open {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  color: #fff;
  font-size: 68px;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  line-height: 48px;
}

.st-pd-video .st-video-open:hover,
.st-sample-img .st-video-open:hover {
  color: rgba(255, 255, 255, 0.7);
}

.st-video-popup {
  position: fixed;
  z-index: 1000;
  top: 0;
  width: 100%;
  height: 100%;
  left: -100%;
  -webkit-transition-delay: 0.3s;
  -o-transition-delay: 0.3s;
  transition-delay: 0.3s;
}

.st-video-popup.active {
  left: 0;
  -webkit-transition-delay: 0s;
  -o-transition-delay: 0s;
  transition-delay: 0s;
  left: 0;
}

.st-video-popup-overlay {
  position: absolute;
  left: 0;
  right: 0;
  background: #000;
  -webkit-transition: all 0.4s ease-out;
  -o-transition: all 0.4s ease-out;
  transition: all 0.4s ease-out;
  opacity: 0;
}

.st-video-popup.active .st-video-popup-overlay {
  opacity: 0.8;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
}

.st-video-popup-content {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  font-size: 0;
  text-align: center;
  -webkit-transition: all 0.4s ease-out;
  -o-transition: all 0.4s ease-out;
  transition: all 0.4s ease-out;
  -webkit-transform: translateY(100px);
  -ms-transform: translateY(100px);
  transform: translateY(100px);
  opacity: 0;
  visibility: hidden;
  padding: 15px;
}

.st-video-popup.active .st-video-popup-content {
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
  opacity: 1;
  visibility: visible;
}

.st-video-popup-content:after {
  content: '';
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}

.st-video-popup-container {
  display: inline-block;
  position: relative;
  text-align: left;
  background: #fff;
  max-width: 1100px;
  width: 100%;
  vertical-align: middle;
}

.st-video-popup-container .embed-responsive {
  width: 100%;
}

.embed-responsive {
  position: relative;
  display: block;
  height: 0;
  padding: 0;
  overflow: hidden;
  height: 100%;
}

.st-video-popup-close {
  position: absolute;
  top: 0;
  right: 0;
  width: 40px;
  height: 40px;
  background: #d90d0d;
  cursor: pointer;
  -webkit-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

.st-video-popup iframe {
  width: 100%;
  height: 100%;
  position: absolute;
}

.st-video-popup-close:before {
  content: '';
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 20px;
  height: 1px;
  background: #fff;
  margin-left: -10px;
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
  -webkit-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

.st-video-popup-close:after {
  content: '';
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 20px;
  height: 1px;
  background: #fff;
  margin-left: -10px;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  -webkit-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

.st-video-popup-close:hover:before,
.st-video-popup-close:hover:after {
  background: #000;
}

.st-video-popup-layer {
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
}

.st-video-popup-align {
  overflow: hidden;
}

/*End Video Popup*/
@media screen and (max-width: 1199px) {
  .st-video-block.st-style1 {
    border-radius: 7px;
  }
}

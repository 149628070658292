@import "./default/variable";

a {
  text-decoration: none;
  &:hover {
    cursor: pointer;
  }
}

.st-hero.st-style1 {
  display: flex !important;
}

.st-hero.st-style1.st-type3 {
  .text-transition > div::before {
    position: initial;
  }
}

.text-transition {
  display: inline-flex !important;
  margin-left: 10px;
  // color: $blue;
  position: relative;
  > div:before {
    content: "";
    position: absolute;
    height: 75px;
    width: 100%;
    background-image: url(../../public/shape/hero-shape.svg);
    bottom: -5px;
    z-index: -1;
  }
}

.cd-headline-style1 {
  .cd-words-wrapper > span {
    background: initial;
    color: $blue;
  }
}

.cd-words-wrapper > span {
  background-image: url(../../public/shape/hero-shape.svg);
}

.index-module_type__E-SaG::after {
  font-weight: 100;
}

.st-back-to-home {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 7px;
  color: #fff;
  font-size: 18px;
  margin-bottom: 15px;

  &:hover {
    color: $blue;
  }
}

.comment-reply-link {
  &::before {
    display: none;
  }
  display: inline-flex;
  gap: 5px;
  align-items: center;
  justify-content: center;
}

.st-visable-element {
  position: relative;
  z-index: 99;
}

.st-slider-style1 {
  position: relative;
  .slick-list {
    overflow: hidden;
  }
  .slick-track {
    display: flex;
  }

  .slick-arrow {
    box-shadow: 0px 5px 10px 0px rgba(106, 106, 106, 0.15);
    position: absolute;
    height: 50px;
    width: 50px;
    border-radius: 50%;
    display: flex !important;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
    background-color: #fff;
    opacity: 0;
    visibility: hidden;
    cursor: pointer;
    transition: all 0.4s ease;
    &:before {
      content: "";
      position: absolute;
      height: 100%;
      width: 30px;
    }
    &.slick-arrow-left {
      left: 30px;
      &:before {
        right: -20px;
      }
    }
    &.slick-arrow-right {
      right: 30px;
      &:before {
        left: -20px;
      }
    }
    &:hover {
      color: $black1;
    }
  }
  &:hover .slick-arrow {
    opacity: 1;
    visibility: visible;
  }

  .slick-dots {
    display: flex !important;
    align-items: center;
    justify-content: center;
    position: absolute;
    bottom: 17px;
    left: 50%;
    transform: translateX(-50%);
    gap: 10px;
    li {
      list-style: none;
      height: 12px;
      width: 12px;
      border-radius: 6px;
      background-color: #666;
      margin-right: 8px;
      cursor: pointer;
      position: relative;
      transition: all 0.4s ease;

      &.slick-active {
        background-color: $blue;
      }

      button {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        z-index: 1;
        opacity: 0;
      }
    }
  }
}
.st-slider-style2 {
  position: relative;
  .slick-slide {
    padding-left: 15px;
    padding-right: 15px;
  }

  .slick-list {
    padding-top: 20px;
    padding-bottom: 20px;
    margin-top: -20px;
    margin-bottom: -20px;
    margin-left: -15px;
    margin-right: -15px;
    overflow: hidden;
  }
  .slick-track {
    display: flex;
  }

  .slick-arrow {
    box-shadow: 0px 5px 10px 0px rgba(106, 106, 106, 0.15);
    position: absolute;
    height: 50px;
    width: 50px;
    border-radius: 50%;
    display: flex !important;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
    background-color: #fff;
    opacity: 0;
    visibility: hidden;
    cursor: pointer;
    transition: all 0.4s ease;
    &:before {
      content: "";
      position: absolute;
      height: 100%;
      width: 30px;
    }
    &.slick-arrow-left {
      left: 0px;
      &:before {
        right: -20px;
      }
    }
    &.slick-arrow-right {
      right: 0px;
      &:before {
        left: -20px;
      }
    }
    &:hover {
      color: $black1;
    }
  }
  &:hover {
    .slick-arrow {
      opacity: 1;
      visibility: visible;
      &.slick-arrow-left {
        left: -70px;
      }
      &.slick-arrow-right {
        right: -70px;
      }
    }
  }

  .slick-dots {
    display: flex !important;
    align-items: center;
    justify-content: center;
    margin: 0;
    margin-top: 20px;
    gap: 10px;
    li {
      list-style: none;
      height: 12px;
      width: 12px;
      border-radius: 6px;
      background-color: #666;
      margin-right: 8px;
      cursor: pointer;
      position: relative;
      transition: all 0.4s ease;

      &.slick-active {
        background-color: $blue;
      }

      button {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        z-index: 1;
        opacity: 0;
      }
    }
  }
}
.st-brand-slider-container {
  overflow: hidden; /* Prevent duplicated slides from overflowing */
}

.st-slider-style2 .slick-slide {
  margin: 0 10px; /* Adjust spacing between slides */
}
.st-slider-style3 {
  position: relative;

  .slick-slide {
    padding-left: 15px;
    padding-right: 15px;

    > div {
      display: flex;

      > .slick-slide-in {
        width: 100%;
      }
    }
  }

  .slick-list {
    padding-top: 20px;
    padding-bottom: 70px;
    margin-top: -20px;
    margin-bottom: -20px;
    margin-left: -15px;
    margin-right: -15px;
    overflow: hidden;
  }

  .slick-track {
    display: flex;
  }

  .slick-arrow {
    height: 42px;
    width: 42px;
    display: flex !important;
    align-items: center;
    justify-content: center;
    border: 1px solid;
    border-radius: 50%;
    cursor: pointer;
    font-size: 14px;
    transition: all 0.3s ease;
    position: absolute;
    bottom: 0;
    &:hover {
      background-color: #666;
      color: #fff;
    }
    &.slick-arrow-left {
      left: 50%;
      transform: translateX(-50px);
    }
    &.slick-arrow-right {
      right: 50%;
      transform: translateX(50px);
    }
  }

  .slick-dots {
    display: none !important;
    // align-items: center;
    // justify-content: center;
    // margin: 0;
    // margin-top: 20px;
    // gap: 10px;
    // li {
    //   list-style: none;
    //   height: 12px;
    //   width: 12px;
    //   border-radius: 6px;
    //   background-color: #666;
    //   margin-right: 8px;
    //   cursor: pointer;
    //   position: relative;
    //   transition: all 0.4s ease;

    //   &.slick-active {
    //     background-color: $blue;
    //   }

    //   button {
    //     position: absolute;
    //     left: 50%;
    //     top: 50%;
    //     transform: translate(-50%, -50%);
    //     z-index: 1;
    //     opacity: 0;
    //   }
    // }
  }
}

.st-iconbox.st-style1 {
  .st-iconbox-icon img {
    width: 52px;
  }
  &.st-type2 .st-iconbox-icon img {
    width: 38px;
  }
}
.my-masonry-grid {
  display: flex;
}

.st-gap-x-5 {
  gap: 5px;
}

.st-gallery-modal-initial-hidden {
  position: absolute;
  z-index: 99999;
  opacity: 0;
}

.st-link-hover-wrap {
  .st-link-hover {
    font-size: 16px;
    position: absolute;
    z-index: 2;
    top: -100px;
    right: 20px;
    width: 50px;
    height: 120px;
    background: $blue;
    border-radius: 0 0 40px 40px;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    opacity: 0;
    transition: all 0.3s ease;

    i {
      color: $blue;
      background-color: $white;
      height: 40px;
      width: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      margin-bottom: 5px;
      opacity: 0;
      transform: scale(0.5) rotate(180deg);
      transition: all 0.3s ease;
    }
  }

  &:hover {
    .st-link-hover {
      opacity: 1;
      top: 0px;

      svg {
        opacity: 1;
        transform: scale(1) rotate(0deg);
        transition-delay: 0.15s;
      }
    }
  }
}

.st-sticky-footer {
  position: initial;
}

.st-footer-widget-nav svg {
  position: absolute;
  left: 0;
  top: 6px;
  font-size: 12px;
}

.st-page-not-found {
  height: calc(100vh - 80px);

  h3 {
    font-size: 120px;
    color: $blue;
    text-shadow: 1px 1px 0 $blue, 1px -1px 0 $blue, -1px 1px 0 $blue,
      -1px -1px 0 $blue;
  }

  h4 {
    color: $blue;
  }

  p {
    text-transform: uppercase;
    margin-bottom: 50px;
  }
}
.st-accordian-body-wrap {
  transition: all 0.3s ease;
}

.st-get-sidebar {
  padding-left: 300px;

  .st-sticky-footer {
    left: initial;
    max-width: initial;
  }
}

/*--------------------------------------------------------------
4. Preloader
----------------------------------------------------------------*/
.st-perloader {
  position: fixed;
  height: 100vh;
  width: 100vw;
  z-index: 99999;
  left: 0;
  top: 0;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;

  .st-perloader-in {
    position: relative;

    svg {
      width: 110px;
      height: 110px;
    }
  }

  // .st-wave-first {
  //   svg {
  //     fill: $black4;
  //   }
  // }

  // .st-wave-second {
  //   position: absolute;
  //   top: 0;
  //   left: 0;
  //   width: 100%;
  //   overflow: hidden;
  //   animation: spark 1.25s linear infinite;

  //   svg {
  //     fill: $blue;
  //   }
  // }
  .st-wave-first,
  .st-wave-second {
    width: 100%; /* Make the waves take up full width */
    height: 100%; /* Make the waves take up full height */
  }

  .st-wave-first {
    animation: wave 5s linear infinite; /* Slower animation for a calmer effect */
  }
  .st-wave-second {
    animation: wave 5s linear infinite;
    animation-delay: -2.5s; /* Adjust the delay for smoother transition */
  }

  @keyframes wave {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(-200%);
    } /* Move waves twice their width for a longer animation */
  }

  .st-wave-1 {
    animation: wave 8s linear infinite;
  }
  .st-wave-2 {
    animation: wave 8s linear infinite;
    animation-delay: -1.6s;
  }
  .st-wave-3 {
    animation: wave 8s linear infinite;
    animation-delay: -3.2s;
  }
  .st-wave-4 {
    animation: wave 8s linear infinite;
    animation-delay: -4.8s;
  }
  .st-wave-5 {
    animation: wave 8s linear infinite;
    animation-delay: -6.4s;
  }

  @keyframes wave {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(-200%);
    }
  }
  /* ----------------------------------------------
 * Generated by Animista on 2024-5-12 12:32:46
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

  /* Add the @-webkit-keyframes and @keyframes provided */

  .loader-container {
    display: grid;
    place-items: center;
  }

  .loader-image {
    transform: translate(-50%, -50%); /* Center the image */
    top: 50%;
    left: 50%;

    // width: 100px; /* Adjust as needed */
    // height: 100px; /* Adjust as needed */
    animation: slide-top 1s cubic-bezier(0.55, 0.085, 0.68, 0.53) both,
      bounce-top 3s; /* Same animation as the text */
  }

  @keyframes image-focus-in {
    /* Identical to text-focus-in */
    0% {
      filter: blur(12px);
      opacity: 0;
    }
    100% {
      filter: blur(0);
      opacity: 1;
    }
  }

  /* Include for older browsers */
  @-webkit-keyframes image-focus-in {
    0% {
      -webkit-filter: blur(12px);
      opacity: 0;
    }
    100% {
      -webkit-filter: blur(0);
      opacity: 1;
    }
  }
  @-webkit-keyframes slide-top {
    0% {
      -webkit-transform: translateY(100px);
      transform: translateY(100px);
    }
    100% {
      -webkit-transform: translateY(-30px);
      transform: translateY(-30px);
    }
  }
  @-webkit-keyframes bounce-top {
    0% {
      -webkit-transform: translateY(-45px);
      transform: translateY(-45px);
      -webkit-animation-timing-function: ease-in;
      animation-timing-function: ease-in;
      opacity: 1;
    }
    24% {
      opacity: 1;
    }
    40% {
      -webkit-transform: translateY(-24px);
      transform: translateY(-24px);
      -webkit-animation-timing-function: ease-in;
      animation-timing-function: ease-in;
    }
    65% {
      -webkit-transform: translateY(-12px);
      transform: translateY(-12px);
      -webkit-animation-timing-function: ease-in;
      animation-timing-function: ease-in;
    }
    82% {
      -webkit-transform: translateY(-6px);
      transform: translateY(-6px);
      -webkit-animation-timing-function: ease-in;
      animation-timing-function: ease-in;
    }
    93% {
      -webkit-transform: translateY(-4px);
      transform: translateY(-4px);
      -webkit-animation-timing-function: ease-in;
      animation-timing-function: ease-in;
    }
    25%,
    55%,
    75%,
    87% {
      -webkit-transform: translateY(0);
      transform: translateY(0);
      -webkit-animation-timing-function: ease-out;
      animation-timing-function: ease-out;
    }
    100% {
      -webkit-transform: translateY(0);
      transform: translateY(0);
      -webkit-animation-timing-function: ease-out;
      animation-timing-function: ease-out;
      opacity: 1;
    }
  }
  @keyframes bounce-top {
    0% {
      -webkit-transform: translateY(-45px);
      transform: translateY(-45px);
      -webkit-animation-timing-function: ease-in;
      animation-timing-function: ease-in;
      opacity: 1;
    }
    24% {
      opacity: 1;
    }
    40% {
      -webkit-transform: translateY(-24px);
      transform: translateY(-24px);
      -webkit-animation-timing-function: ease-in;
      animation-timing-function: ease-in;
    }
    65% {
      -webkit-transform: translateY(-12px);
      transform: translateY(-12px);
      -webkit-animation-timing-function: ease-in;
      animation-timing-function: ease-in;
    }
    82% {
      -webkit-transform: translateY(-6px);
      transform: translateY(-6px);
      -webkit-animation-timing-function: ease-in;
      animation-timing-function: ease-in;
    }
    93% {
      -webkit-transform: translateY(-4px);
      transform: translateY(-4px);
      -webkit-animation-timing-function: ease-in;
      animation-timing-function: ease-in;
    }
    25%,
    55%,
    75%,
    87% {
      -webkit-transform: translateY(0);
      transform: translateY(0);
      -webkit-animation-timing-function: ease-out;
      animation-timing-function: ease-out;
    }
    100% {
      -webkit-transform: translateY(0);
      transform: translateY(0);
      -webkit-animation-timing-function: ease-out;
      animation-timing-function: ease-out;
      opacity: 1;
    }
  }
  @keyframes slide-top {
    0% {
      -webkit-transform: translateY(100px);
      transform: translateY(100px);
    }
    100% {
      -webkit-transform: translateY(-30px);
      transform: translateY(-30px);
    }
  }
  .loader-text {
    font-size: 1.5rem;
    font-weight: bold;
    padding: 10px 20px; /* Add padding around the text */
    background: linear-gradient(to right, #fbd725, #d4911c);
    background-size: 200% auto; /* Ensure gradient covers the blurred text */
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: tracking-in-expand 1s cubic-bezier(0.55, 0.085, 0.68, 0.53) both,
      gradient-shift 12s linear infinite; /* Added gradient animation */
  }
  @keyframes gradient-shift {
    0% {
      background-position: right center;
    }
    100% {
      background-position: left center;
    }
  }
  @-webkit-keyframes tracking-in-expand {
    0% {
      letter-spacing: -0.5em;
      opacity: 0;
    }
    40% {
      opacity: 0.6;
    }
    100% {
      opacity: 1;
    }
  }
  @keyframes tracking-in-expand {
    0% {
      letter-spacing: -0.5em;
      opacity: 0;
    }
    40% {
      opacity: 0.6;
    }
    100% {
      opacity: 1;
    }
  }
  &.st-color1 {
    .st-wave-second {
      svg {
        fill: $light-blue;
      }
    }
  }
  &.st-color2 {
    .st-wave-second {
      svg {
        fill: $pink;
      }
    }
  }
}

@keyframes spark {
  0% {
    max-width: 0;
  }

  100% {
    max-width: 100%;
  }
}
